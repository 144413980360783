<app-page-template [title]="title">
    <form [formGroup]="formGroup" class="col-12 overflow-hidden">
        <div class="cards-container row">
            <div class="col-12">
                <mat-card *ngIf="personaJuridica" class="card shadow">
                    <mat-card-title>
                        {{'OPERADORES.raoSocial' | translate}}
                    </mat-card-title>
                    <mat-card-content>
                        <div class="row d-flex">
                            <br/>
                            <div class="col-2 mr-auto">
                                <app-input-text
                                        formControlName="cif"
                                        [label]="'CIF'"
                                        [showLabel]="true"
                                        [campoObligatorio]="true"
                                        [isDisabled]="true"
                                        (blur)="onBlur()"
                                >
                                </app-input-text>
                            </div>
                            <div class="col-8 mr-auto">
                                <app-input-text
                                        formControlName="empresa"
                                        [label]="'Empresa'"
                                        [showLabel]="true"
                                        [campoObligatorio]="true"
                                        (blur)="onBlur()"
                                >
                                </app-input-text>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-card class="card shadow">
                    <mat-card-title>
                        {{'OPERADORES.titular' | translate}}
                    </mat-card-title>
                    <mat-card-content>
                        <div class="row d-flex">
                            <div class="col-2 mr-auto">
                                <app-input-text
                                        formControlName="nif"
                                        [label]="'NIF/DNI/NIE'"
                                        [showLabel]="true"
                                        [campoObligatorio]="true"
                                        [isDisabled]="!personaJuridica"
                                        (blur)="onBlur()"
                                >
                                </app-input-text>
                            </div>
                            <div class="col-2 mr-auto">
                                <app-input-text
                                        formControlName="nombre"
                                        [label]="'Nom'"
                                        [showLabel]="true"
                                        [campoObligatorio]="true"
                                        (blur)="onBlur()"
                                >
                                </app-input-text>
                            </div>
                            <div class="col-2 mr-auto">
                                <app-input-text
                                        formControlName="apellido1"
                                        [label]="'Primer cognom'"
                                        [showLabel]="true"
                                        [campoObligatorio]="true"
                                        (blur)="onBlur()"
                                >
                                </app-input-text>
                            </div>
                            <div class="col-2 mr-auto">
                                <app-input-text
                                        formControlName="apellido2"
                                        [label]="'Segon cognom'"
                                        [showLabel]="true"
                                        (blur)="onBlur()"
                                >
                                </app-input-text>
                            </div>
                            <div class="col-2 mr-auto">
                                <app-input-select
                                        [formControlName]="'idGenero'"
                                        [label]="'general.genero' | translate"
                                        [options]="generos | async | arrayToSelectItem:'idGenero':'descripcion'"
                                        [campoObligatorio]="true"
                                        (valueChanged)="onBlur()"
                                >
                                </app-input-select>
                            </div>
                        </div>
                        <div class="row d-flex">
                            <div class="col-2 mr-auto">
                                <app-input-text
                                        formControlName="movil"
                                        [label]="'Mòbil'"
                                        [showLabel]="true"
                                        [campoObligatorio]="true"
                                        (blur)="onBlur()"
                                >
                                </app-input-text>
                            </div>
                            <div class="col-4 mr-auto">
                                <app-input-text
                                        formControlName="email"
                                        [label]="'E-mail'"
                                        [showLabel]="true"
                                        [campoObligatorio]="true"
                                        (blur)="onBlur()"
                                >
                                </app-input-text>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-card class="card shadow" >
                    <mat-card-title>
                        {{'general.direccionSocial' | translate}}
                    </mat-card-title>
                    <mat-card-content>
                        <div class="row justify-content-start">
                            <app-operadores-localizacion
                                    [municipioInput]="municipioSocial"
                                    (formValid)="municipioSocialValid = $event"
                                    (municipioModified)="municipioSocial = $event"
                                    [campoObligatorio]="true"
                            >
                            </app-operadores-localizacion>
                        </div>
                        <div class="row justify-content-start">
                            <div class="col-4">
                                <app-input-text
                                        [formControlName]="'direccionSocial'"
                                        [label]="'general.direccion' | translate"
                                        [showLabel]="true"
                                        [campoObligatorio]="true"
                                        (blur)="onBlur()"
                                >
                                </app-input-text>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-card class="card shadow">
                    <mat-card-title>
                        {{'general.direccionPostal' | translate}}
                    </mat-card-title>
                    <mat-card-content>
                        <div class="row justify-content-start">
                            <app-operadores-localizacion
                                    [municipioInput]="municipioPostal"
                                    (formValid)="municipioPostalValid = $event"
                                    (municipioModified)="municipioPostal = $event"
                                    [campoObligatorio]="true"
                            >
                            </app-operadores-localizacion>
                        </div>
                        <div class="row justify-content-start">
                            <div class="col-4">
                                <app-input-text
                                        [formControlName]="'direccionPostal'"
                                        [label]="'general.direccion' | translate"
                                        [showLabel]="true"
                                        [campoObligatorio]="true"
                                        (blur)="onBlur()"
                                >
                                </app-input-text>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-card class="card shadow">
                    <mat-card-title>
                        {{'OPERADORES.datosNotificacionElectronica' | translate}}
                    </mat-card-title>
                    <mat-card-content>
                        <div class="row">
                            <div class="col-2 mr-auto checkProcedente">
                                <app-input-checkbox
                                        formControlName="copiarDatosTitular"
                                        (valueChanged)="copyTitularNotif($event)"
                                        [label]="'OPERADORES.copiarDatosTitular' | translate"
                                        [isDisabled]="!this.formGroup.get('movil')?.valid || !this.formGroup.get('email')?.valid"
                                >
                                </app-input-checkbox>
                                <br/>
                            </div>
                        </div>
                        <div class="row justify-content-start">
                            <div class="col-2 mr-auto">
                                <app-input-text
                                        formControlName="movilNotif"
                                        [label]="'Mobil notificació'"
                                        [showLabel]="true"
                                        [campoObligatorio]="true"
                                        (blur)="onBlur()"
                                >
                                </app-input-text>
                            </div>
                            <div class="col-4 mr-auto">
                                <app-input-text
                                        formControlName="emailNotif"
                                        [label]="'E-mail notificació'"
                                        [showLabel]="true"
                                        [campoObligatorio]="true"
                                        (blur)="onBlur()"
                                >
                                </app-input-text>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-card class="card shadow">
                    <mat-card-title>
                        {{'OPERADORES.datosContactoCCPAE' | translate}}
                    </mat-card-title>
                    <mat-card-content>
                        <div class="row">
                            <div class="col-2 mr-auto checkProcedente">
                                <app-input-checkbox
                                        formControlName="copiarDatosTitular"
                                        (valueChanged)="copyTitularRep($event)"
                                        [label]="'OPERADORES.copiarDatosTitular' | translate"
                                        [isDisabled]="!isFormDatosTitularValid()"
                                >
                                </app-input-checkbox>
                                <br/>
                            </div>
                        </div>
                        <div class="row d-flex">
                            <div class="col-2 mr-auto">
                                <app-input-text
                                        formControlName="nifRep"
                                        [label]="'NIF/DNI/NIE'"
                                        [showLabel]="true"
                                        [campoObligatorio]="true"
                                        (blur)="onBlur()"
                                >
                                </app-input-text>
                            </div>
                            <div class="col-2 mr-auto">
                                <app-input-text
                                        formControlName="nombreRep"
                                        [label]="'Nom'"
                                        [showLabel]="true"
                                        [campoObligatorio]="true"
                                        (blur)="onBlur()"
                                >
                                </app-input-text>
                            </div>
                            <div class="col-2 mr-auto">
                                <app-input-text
                                        formControlName="apellido1Rep"
                                        [label]="'Primer cognom'"
                                        [showLabel]="true"
                                        [campoObligatorio]="true"
                                        (blur)="onBlur()"
                                >
                                </app-input-text>
                            </div>
                            <div class="col-2 mr-auto">
                                <app-input-text
                                        formControlName="apellido2Rep"
                                        [label]="'Segon cognom'"
                                        [showLabel]="true"
                                        (blur)="onBlur()"
                                >
                                </app-input-text>
                            </div>
                            <div class="col-2 mr-auto">
                                <app-input-select
                                        [formControlName]="'idGeneroRep'"
                                        [label]="'general.genero' | translate"
                                        [options]="generos | async | arrayToSelectItem:'idGenero':'descripcion'"
                                        [campoObligatorio]="true"
                                        (valueChanged)="onBlur()"
                                >
                                </app-input-select>
                            </div>
                        </div>
                        <div class="row d-flex">
                            <div class="col-2 mr-auto">
                                <app-input-text
                                        formControlName="movilRep"
                                        [label]="'Mòbil'"
                                        [showLabel]="true"
                                        [campoObligatorio]="true"
                                        (blur)="onBlur()"
                                >
                                </app-input-text>
                            </div>
                            <div class="col-4 mr-auto">
                                <app-input-text
                                        formControlName="emailRep"
                                        [label]="'E-mail'"
                                        [showLabel]="true"
                                        [campoObligatorio]="true"
                                        (blur)="onBlur()"
                                >
                                </app-input-text>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-card class="card shadow">
                    <mat-card-title>
                        {{'OPERADORES.webXarxesSocials' | translate}}
                    </mat-card-title>
                    <mat-card-content>
                        <div class="row justify-content-start">
                            <div class="col-4">
                                <app-input-text
                                        [formControlName]="'web'"
                                        [label]="'general.web' | translate"
                                        [showLabel]="true"
                                        (blur)="onBlur()"
                                >
                                </app-input-text>
                            </div>
                            <div class="col-4">
                                <app-input-text
                                        [formControlName]="'facebook'"
                                        [label]="'general.facebook' | translate">
                                    [showLabel]="true"
                                    (blur)="onBlur()"
                                    >
                                </app-input-text>
                            </div>
                        </div>
                        <div class="row justify-content-start">

                            <div class="col-4">
                                <app-input-text
                                        [formControlName]="'twitter'"
                                        [label]="'general.twitter' | translate"
                                        [showLabel]="true"
                                        (blur)="onBlur()"
                                >
                                </app-input-text>
                            </div>
                            <div class="col-4">
                                <app-input-text
                                        [formControlName]="'instagram'"
                                        [label]="'general.instagram' | translate"
                                        [showLabel]="true"
                                        (blur)="onBlur()"
                                >
                                </app-input-text>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-card class="card shadow">
                    <mat-card-title>
                        {{'OPERADORES.datosProduccion' | translate}}
                    </mat-card-title>
                    <mat-card-content>
                        <br/>
                        <div class="row">
                            <div class="col-md-2 col-sm-6">
                                <app-input-checkbox formControlName="_1_produccioPrimaria" [label]="'Producció primària' | translate" (valueChanged)="onSectionChange('_1_produccioPrimaria')" ></app-input-checkbox>
                                <div *ngIf="formGroup.get('_1_produccioPrimaria')?.value">
                                    <br/>
                                    <app-input-checkbox formControlName="_1_1_produccioVegetal" [label]="'Producció vegetal' | translate" (valueChanged)="onFamilyChange('_1_1_produccioVegetal')" ></app-input-checkbox>
                                    <div *ngIf="formGroup.get('_1_1_produccioVegetal')?.value" class="px-4 text-muted">
                                        <app-input-checkbox formControlName="_1_1_1_llenyosos" [label]="'Llenyosos' | translate" (valueChanged)="onBlur()" ></app-input-checkbox>
                                        <app-input-checkbox formControlName="_1_1_2_extensius" [label]="'Extensius' | translate" (valueChanged)="onBlur()" ></app-input-checkbox>
                                        <app-input-checkbox formControlName="_1_1_3_horticoles" [label]="'Hortícoles' | translate" (valueChanged)="onBlur()" ></app-input-checkbox>
                                        <app-input-checkbox formControlName="_1_1_4_obtentorsMRV" [label]="'Obtentors MRV' | translate" (valueChanged)="onBlur()" ></app-input-checkbox>
                                        <app-input-checkbox formControlName="_1_1_5_pastures" [label]="'Pastures' | translate" (valueChanged)="onBlur()" ></app-input-checkbox>
                                        <app-input-checkbox formControlName="_1_1_6_forestals" [label]="'Forestals' | translate" (valueChanged)="onBlur()" ></app-input-checkbox>
                                    </div>
                                    <br/>
                                    <app-input-checkbox formControlName="_1_2_produccioRamadera" [label]="'Producció ramadera' | translate" (valueChanged)="onFamilyChange('_1_2_produccioRamadera')" ></app-input-checkbox>
                                    <div *ngIf="formGroup.get('_1_2_produccioRamadera')?.value" class="px-4 text-muted">
                                        <app-input-checkbox formControlName="_1_2_1_herbivors" [label]="'Herbívors' | translate" (valueChanged)="onBlur()" ></app-input-checkbox>
                                        <app-input-checkbox formControlName="_1_2_2_porcs" [label]="'Porcs' | translate" (valueChanged)="onBlur()" ></app-input-checkbox>
                                        <app-input-checkbox formControlName="_1_2_3_avicola" [label]="'Avícola' | translate" (valueChanged)="onBlur()" ></app-input-checkbox>
                                        <app-input-checkbox formControlName="_1_2_4_apicola" [label]="'Apícola' | translate" (valueChanged)="onBlur()" ></app-input-checkbox>
                                        <app-input-checkbox formControlName="_1_2_5_helicicola" [label]="'Helicícola' | translate" (valueChanged)="onBlur()" ></app-input-checkbox>
                                        <app-input-checkbox formControlName="_1_2_6_insectes" [label]="'Insectes' | translate" (valueChanged)="onBlur()" ></app-input-checkbox>
                                        <app-input-checkbox formControlName="_1_2_7_conills" [label]="'Conills' | translate" (valueChanged)="onBlur()" ></app-input-checkbox>
                                    </div>
                                    <br/>
                                    <app-input-checkbox formControlName="_1_3_recolleccioSilvestre" [label]="'Recol·lecció silvestre' | translate" (valueChanged)="onBlur()" ></app-input-checkbox>
                                    <br/>
                                    <app-input-checkbox formControlName="_1_4_produccioDeSal" [label]="'Producció de sal' | translate" (valueChanged)="onBlur()" ></app-input-checkbox>
                                    <br/>
                                    <app-input-checkbox formControlName="_1_5_aquicultura" [label]="'Aqüicultura' | translate" (valueChanged)="onFamilyChange('_1_5_aquicultura')" ></app-input-checkbox>
                                    <div *ngIf="formGroup.get('_1_5_aquicultura')?.value" class="px-4 text-muted">
                                        <app-input-checkbox formControlName="_1_5_1_alguesIFitoplancton" [label]="'Algues i fitoplàncton' | translate" (valueChanged)="onBlur()" ></app-input-checkbox>
                                        <app-input-checkbox formControlName="_1_5_2_molluscs" [label]="'Mol·luscs' | translate" (valueChanged)="onBlur()" ></app-input-checkbox>
                                        <app-input-checkbox formControlName="_1_5_3_peixos" [label]="'Peixos' | translate" (valueChanged)="onBlur()" ></app-input-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6">
                                <app-input-checkbox formControlName="_2_preparacio" [label]="'Preparació' | translate" (valueChanged)="onBlur()" ></app-input-checkbox>
                            </div>
                            <div class="col-md-2 col-sm-6">
                                <app-input-checkbox formControlName="_3_importacioExportacio" [label]="'Importació i/o exportació' | translate" (valueChanged)="onSectionChange('_3_importacioExportacio')"></app-input-checkbox>
                                <div *ngIf="formGroup.get('_3_importacioExportacio')?.value">
                                    <br/>
                                    <app-input-checkbox formControlName="_3_1_importacio" [label]="'Importació' | translate" (valueChanged)="onBlur()" ></app-input-checkbox>
                                    <br/>
                                    <app-input-checkbox formControlName="_3_2_exportacio" [label]="'Exportació' | translate" (valueChanged)="onBlur()" ></app-input-checkbox>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6">
                                <app-input-checkbox formControlName="_4_distribucioMajorista" [label]="'Distribució majorista' | translate" (valueChanged)="onBlur()" ></app-input-checkbox>
                            </div>
                            <div class="col-md-2 col-sm-6">
                                <app-input-checkbox formControlName="_5_distribucioMinorista" [label]="'Distribució minorista' | translate" (valueChanged)="onSectionChange('_5_distribucioMinorista')" ></app-input-checkbox>
                                <div *ngIf="formGroup.get('_5_distribucioMinorista')?.value">
                                    <br/>
                                    <app-input-checkbox formControlName="_5_1_certificatGran" [label]="'Certificat gran' | translate" (valueChanged)="onCertificatChange('_5_1_certificatGran', $event)" ></app-input-checkbox>
                                    <br/>
                                    <app-input-checkbox formControlName="_5_2_certificatPetit" [label]="'Certificat petit' | translate" (valueChanged)="onCertificatChange('_5_2_certificatPetit', $event)" ></app-input-checkbox>
                                    <br/>
                                    <app-input-checkbox formControlName="_5_3_noCertificatEximit" [label]="'No certificat, eximit' | translate" (valueChanged)="onCertificatChange('_5_3_noCertificatEximit', $event)" ></app-input-checkbox>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6">
                                <app-input-checkbox formControlName="_6_altres" [label]="'Altres activitats' | translate" (valueChanged)="onBlur()" ></app-input-checkbox>
                            </div>
                        </div>
                        <br/>
                        <div *ngIf="formGroup.errors?.produccionError" class="text-danger">
                            <p>* És necessari seleccionar almenys una de les seccions: <span style="font-weight: bold;">producció primària, preparació, importació i/o exportació, distribució majorista, distribució minorista, altres activitats.</span></p>
                            <p>* Si has seleccionat la secció de <span style="font-weight: bold;">producció primària</span>, has de seleccionar almenys una de les seves famílies: <span style="font-weight: bold;">producció vegetal, producció ramadera, recol·lecció silvestre, producció de sal, aqüicultura.</span></p>
                            <p>* Si has seleccionat la secció de <span style="font-weight: bold;">importació i/o exportació</span>, has de seleccionar almenys una de les seves famílies: <span style="font-weight: bold;">importació o exportació</span>.</p>
                        </div>
                    </mat-card-content>

                    <mat-card-content>
                        <div class="row">
                            <div class="col-md-4 col-sm-6">
                                <br/>
                                <app-input-select
                                        formControlName="actividadPrincipal"
                                        [label]="'OPERADORES.actPrincipal' | translate"
                                        [options]="actividadesPrincipales | async | arrayToSelectItem:'idActividadPrincipal':'descripcion'"
                                        [campoObligatorio]="true"
                                        (valueChanged)="onBlur()"
                                >
                                </app-input-select>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>

            </div>
        </div>

        <div class="col-12 p-2 mt-2 d-flex justify-content-end">
            <button mat-raised-button color="basic" class="px-2" style="margin-right: 10px;"
                    (click)="onGuardar()">
                {{'ETIQUETAS.guardarBorrador' | translate}}
            </button>
            <button mat-raised-button color="primary" class="px-2" [disabled]="!formGroup.valid"
                    (click)="onConfirmar()">
                {{'ETIQUETAS.continuarTramitacion' | translate}}
            </button>
        </div>
        <div *ngIf="!formGroup.valid" class="col-12 p-2 mt-2 d-flex justify-content-end text-danger">
            <p>El botó <span style="font-weight: bold;">Continuar tramitació</span> s'activarà quan tots els camps obligatoris del formulari estiguin completats.</p>
        </div>
    </form>
</app-page-template>

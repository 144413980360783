import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

import { Apantallamiento } from "../../../../core/model/core/pages/apantallamiento";
import { ToastService } from "../../../../core/services/components/toast.service";
import { SolicitudInscritoHttpService } from "../../../../core/services/http/operadores/solicitud-inscrito.http.service";
import { OperadoresService } from "../../services/operadores.service";
import { OperadoresHttpService } from "../../../../core/services/http/operadores-http.service";
import { GenerosService } from "../../../../core/services/http/generos.service";

import { SolicitudInscrito } from "../../../../core/model/operadores/solicitudInscrito";
import { ActividadPrincipal } from "../../../../core/model/operadores/actividadPrincipal";
import { Genero } from "../../../../core/model/operadores/genero";
import { Municipio } from "../../../../core/model/localizacion/municipio";

export interface SolicitudInscritoTramite {
  onConfirmar(): void;
}

@Component({
  selector: 'app-operadores-solicitud-inscrito',
  templateUrl: './operadores-solicitud-inscrito.component.html',
  styleUrls: ['./operadores-solicitud-inscrito.component.scss']
})
export class OperadoresSolicitudInscritoComponent extends Apantallamiento implements OnInit, SolicitudInscritoTramite {

  // Services
  protected operadoresSvc: OperadoresService;
  protected operadorSvc: OperadoresHttpService;
  protected solicitudInscritoSvc: SolicitudInscritoHttpService;
  protected toastSvc: ToastService;
  protected generoSvc: GenerosService;

  // Variables
  protected formGroup: FormGroup;
  protected idSolicitudInscrito: string;
  protected title: string;
  protected solicitudInscrito: SolicitudInscrito;
  protected formIsValid: boolean = false;
  protected municipioSocial: Municipio;
  protected municipioPostal: Municipio;
  protected municipioSocialValid = false;
  protected municipioPostalValid = false;
  protected generos: Observable<Genero[]>
  protected actividadesPrincipales: Observable<ActividadPrincipal[]>
  protected formProduccionValid: boolean = false;
  protected personaJuridica: boolean = false;

  constructor(protected injector: Injector,
              private translateSvc: TranslateService,
              protected cdr: ChangeDetectorRef) {
    super();
    this.operadoresSvc = injector.get(OperadoresService);
    this.operadorSvc = injector.get(OperadoresHttpService);
    this.solicitudInscritoSvc = injector.get(SolicitudInscritoHttpService);
    this.toastSvc = injector.get(ToastService);
    this.generoSvc = injector.get(GenerosService);

    this.actividadesPrincipales = this.operadorSvc.getActividadesPrincipales();

    this.formGroup = new FormGroup({
      'idSolicitudInscrito': new FormControl(''),
      'personaFisica': new FormControl(false),
      'cif': new FormControl('', [this.formatoCIFValidator(), Validators.required]),
      'empresa': new FormControl('', [Validators.maxLength(150), Validators.required]),
      'nif': new FormControl('', [this.formatoNIFValidator(), Validators.required]),
      'nombre': new FormControl('', [Validators.maxLength(30), Validators.required]),
      'apellido1': new FormControl('', [Validators.maxLength(30), Validators.required]),
      'apellido2': new FormControl('', Validators.maxLength(30)),
      'idGenero': new FormControl('', Validators.required),
      'email': new FormControl('', [Validators.maxLength(70), Validators.required]),
      'movil': new FormControl('', [Validators.maxLength(11), Validators.pattern('^[0-9]*$'), Validators.required]),
      'direccionSocial': new FormControl('', [Validators.maxLength(60), Validators.required]),
      "municipioSocial": new FormControl(null),
      'direccionPostal': new FormControl('', [Validators.maxLength(60), Validators.required]),
      "municipioPostal": new FormControl(null),
      'emailNotif': new FormControl('', [Validators.maxLength(400), Validators.required]),
      'movilNotif': new FormControl('', [Validators.maxLength(11), Validators.pattern('^[0-9]*$'), Validators.required]),
      'nifRep': new FormControl('', [this.formatoNIFValidator(), Validators.required]),
      'nombreRep': new FormControl('', [Validators.maxLength(30), Validators.required]),
      'apellido1Rep': new FormControl('', [Validators.maxLength(30), Validators.required]),
      'apellido2Rep': new FormControl('', Validators.maxLength(30)),
      'idGeneroRep': new FormControl('', Validators.required),
      'movilRep': new FormControl('', [Validators.maxLength(11), Validators.pattern('^[0-9]*$'), Validators.required]),
      'emailRep': new FormControl('', [Validators.maxLength(70), Validators.required]),
      'web': new FormControl('', Validators.maxLength(70)),
      'facebook': new FormControl('', Validators.maxLength(200)),
      'twitter': new FormControl('', Validators.maxLength(100)),
      'instagram': new FormControl('', Validators.maxLength(200)),
      'actividadPrincipal': new FormControl(null, Validators.required),
      'mixta': new FormControl(false),
      '_1_produccioPrimaria': new FormControl(null),
      '_1_1_produccioVegetal': new FormControl(null),
      '_1_1_1_llenyosos': new FormControl(null),
      '_1_1_2_extensius': new FormControl(null),
      '_1_1_3_horticoles': new FormControl(null),
      '_1_1_4_obtentorsMRV': new FormControl(null),
      '_1_1_5_pastures': new FormControl(null),
      '_1_1_6_forestals': new FormControl(null),
      '_1_2_produccioRamadera': new FormControl(null),
      '_1_2_1_herbivors': new FormControl(null),
      '_1_2_2_porcs': new FormControl(null),
      '_1_2_3_avicola': new FormControl(null),
      '_1_2_4_apicola': new FormControl(null),
      '_1_2_5_helicicola': new FormControl(null),
      '_1_2_6_insectes': new FormControl(null),
      '_1_2_7_conills': new FormControl(null),
      '_1_3_recolleccioSilvestre': new FormControl(null),
      '_1_4_produccioDeSal': new FormControl(null),
      '_1_5_aquicultura': new FormControl(null),
      '_1_5_1_alguesIFitoplancton': new FormControl(null),
      '_1_5_2_molluscs': new FormControl(null),
      '_1_5_3_peixos': new FormControl(null),
      '_2_preparacio': new FormControl(null),
      '_3_importacioExportacio': new FormControl(null),
      '_3_1_importacio': new FormControl(null),
      '_3_2_exportacio': new FormControl(null),
      '_4_distribucioMajorista': new FormControl(null),
      '_5_distribucioMinorista': new FormControl(null),
      '_5_1_certificatGran': new FormControl(null),
      '_5_2_certificatPetit': new FormControl(null),
      '_5_3_noCertificatEximit': new FormControl(null),
      '_6_altres': new FormControl(null),
    }, { validators: this.produccionValidator() });

  }

  ngOnInit(): void {
    this.operadoresSvc.solicitudInscrito;
    this.solicitudInscrito = this.operadoresSvc.solicitudInscrito;
    this.idSolicitudInscrito = this.solicitudInscrito.idSolicitudInscrito;
    this.title = "Sol·licitud d'inscripció: " + this.idSolicitudInscrito;
    this.generos = this.generoSvc.getGeneros();

    this.solicitudInscritoSvc.getSolicitudInscrito(this.idSolicitudInscrito).subscribe(data => {
      this.solicitudInscrito = data;
      this.formGroup.patchValue(data);
      this.municipioSocial = data.municipioSocial;
      this.municipioPostal = data.municipioPostal;
      this.personaJuridica = !!data.cif;
      this.updateValidators();
    });
  }

  onBlur(): void {
    this.updateSolicitudInscritoFromForm();
    this.solicitudInscritoSvc.updateSolicitudInscrito(this.idSolicitudInscrito, this.solicitudInscrito)
        .subscribe(() => {
        });
  }

  private updateSolicitudInscritoFromForm(): void {
    const formValue = this.formGroup.value;
    Object.keys(formValue).forEach(key => {
      if (formValue[key] === '') {
        formValue[key] = null;
      }
    });

    this.solicitudInscrito = {
      ...this.solicitudInscrito,
      ...formValue,
      municipioSocial: this.formGroup.get('municipioSocial')?.value || this.municipioSocial,
      municipioPostal: this.formGroup.get('municipioPostal')?.value || this.municipioPostal
    };
  }

  private updateValidators(): void {
    if (this.personaJuridica) {
      this.formGroup.get('cif')?.setValidators([this.formatoCIFValidator(), Validators.required]);
      this.formGroup.get('empresa')?.setValidators([Validators.maxLength(150), Validators.required]);
    } else {
      this.formGroup.get('cif')?.clearValidators();
      this.formGroup.get('empresa')?.clearValidators();
    }
    this.formGroup.get('cif')?.updateValueAndValidity();
    this.formGroup.get('empresa')?.updateValueAndValidity();
  }

  protected copyTitularNotif($event: any) {
    if ($event) {
      const movil = this.formGroup.get('movil')?.value;
      const email = this.formGroup.get('email')?.value;
      this.formGroup.get('movilNotif')?.setValue(movil);
      this.formGroup.get('emailNotif')?.setValue(email);
      this.onBlur();
      this.formGroup.get('movilNotif')?.disable();
      this.formGroup.get('emailNotif')?.disable();
    } else {
      this.formGroup.get('movilNotif')?.setValue(null);
      this.formGroup.get('emailNotif')?.setValue(null);
      this.formGroup.get('movilNotif')?.enable();
      this.formGroup.get('emailNotif')?.enable();
    }
    this.formGroup.get('movilNotif')?.updateValueAndValidity();
    this.formGroup.get('emailNotif')?.updateValueAndValidity();
    this.onBlur(); // Call onBlur() after all updates
  }

  isFormDatosTitularValid(): boolean {
    return this.formGroup.get('nif')?.valid! &&
        this.formGroup.get('nombre')?.valid! &&
        this.formGroup.get('apellido1')?.valid! &&
        this.formGroup.get('idGenero')?.valid! &&
        this.formGroup.get('email')?.valid! &&
        this.formGroup.get('movil')?.valid!;
  }

  protected copyTitularRep($event: any) {
    if ($event) {
      const nif = this.formGroup.get('nif')?.value;
      const nombre = this.formGroup.get('nombre')?.value;
      const apellido1 = this.formGroup.get('apellido1')?.value;
      const apellido2 = this.formGroup.get('apellido2')?.value;
      const idGenero = this.formGroup.get('idGenero')?.value;
      const movil = this.formGroup.get('movil')?.value;
      const email = this.formGroup.get('email')?.value;
      this.formGroup.get('nifRep')?.setValue(nif);
      this.formGroup.get('nombreRep')?.setValue(nombre);
      this.formGroup.get('apellido1Rep')?.setValue(apellido1);
      this.formGroup.get('apellido2Rep')?.setValue(apellido2);
      this.formGroup.get('idGeneroRep')?.setValue(idGenero);
      this.formGroup.get('movilRep')?.setValue(movil);
      this.formGroup.get('emailRep')?.setValue(email);
      this.onBlur();
      this.formGroup.get('nifRep')?.disable();
      this.formGroup.get('nombreRep')?.disable();
      this.formGroup.get('apellido1Rep')?.disable();
      this.formGroup.get('apellido2Rep')?.disable();
      this.formGroup.get('idGeneroRep')?.disable();
      this.formGroup.get('movilRep')?.disable();
      this.formGroup.get('emailRep')?.disable();
    } else {
        this.formGroup.get('nifRep')?.setValue(null);
        this.formGroup.get('nombreRep')?.setValue(null);
        this.formGroup.get('apellido1Rep')?.setValue(null);
        this.formGroup.get('apellido2Rep')?.setValue(null);
        this.formGroup.get('idGeneroRep')?.setValue(null);
        this.formGroup.get('movilRep')?.setValue(null);
        this.formGroup.get('emailRep')?.setValue(null);
        this.onBlur();
        this.formGroup.get('nifRep')?.enable();
        this.formGroup.get('nombreRep')?.enable();
        this.formGroup.get('apellido1Rep')?.enable();
        this.formGroup.get('apellido2Rep')?.enable();
        this.formGroup.get('idGeneroRep')?.enable();
        this.formGroup.get('movilRep')?.enable();
        this.formGroup.get('emailRep')?.enable();
    }
    this.formGroup.get('nifRep')?.updateValueAndValidity();
    this.formGroup.get('nombreRep')?.updateValueAndValidity();
    this.formGroup.get('apellido1Rep')?.updateValueAndValidity();
    this.formGroup.get('apellido2Rep')?.updateValueAndValidity();
    this.formGroup.get('idGeneroRep')?.updateValueAndValidity();
    this.formGroup.get('movilRep')?.updateValueAndValidity();
    this.formGroup.get('emailRep')?.updateValueAndValidity();
    this.onBlur();
  }

  private formatoCIFValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const pattern = /^[A-Za-z]\d{7}[A-Za-z0-9]$/;

      if (!value) {
        return null;
      }

      const CIFFormatOK = pattern.test(value);

      return !CIFFormatOK ? { CIFValid: true } : null;
    };
  }

  private formatoNIFValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const pattern = /^(\d{8}[A-Za-z]|[A-Za-z]\d{7}[A-Za-z0-9])$/;

      if (!value) {
        return null;
      }

      const NIFFormatOK = pattern.test(value);

      return !NIFFormatOK ? { NIFValid: true } : null;
    };
  }

  onConfirmar(): void {
    this.updateSolicitudInscritoFromForm(); // Ensure form data is updated
    this.solicitudInscritoSvc.updateSolicitudInscrito(this.idSolicitudInscrito, this.solicitudInscrito)
        .subscribe(() => {
          this.operadoresSvc.enviarSolicitudInscrito()
              .subscribe(_ => {
                window.self.close();
              });
        });
  }

  onGuardar(): void {
    this.updateSolicitudInscritoFromForm();
    this.solicitudInscritoSvc.updateSolicitudInscrito(this.idSolicitudInscrito, this.solicitudInscrito)
        .subscribe(() => {
          this.toastSvc.showSuccess(this.translateSvc.instant("ETIQUETAS.guardarSolicitudOk"));
        });
  }

  onSectionChange(section: string) {
    const sectionControl = this.formGroup.get(section);
    if (sectionControl) {
      if (section === '_1_produccioPrimaria') {
        if (sectionControl.value) {
          this.formGroup.get('_1_1_produccioVegetal')?.setValue(true);
        } else {
          const controlsToReset = [
            '_1_1_produccioVegetal', '_1_1_1_llenyosos', '_1_1_2_extensius', '_1_1_3_horticoles',
            '_1_1_4_obtentorsMRV', '_1_1_5_pastures', '_1_1_6_forestals', '_1_2_produccioRamadera',
            '_1_2_1_herbivors', '_1_2_2_porcs', '_1_2_3_avicola', '_1_2_4_apicola', '_1_2_5_helicicola',
            '_1_2_6_insectes', '_1_2_7_conills', '_1_3_recolleccioSilvestre', '_1_4_produccioDeSal',
            '_1_5_aquicultura', '_1_5_1_alguesIFitoplancton', '_1_5_2_molluscs', '_1_5_3_peixos'
          ];
          controlsToReset.forEach(controlName => {
            this.formGroup.get(controlName)?.setValue(false);
          });
        }
      } else if (section === '_3_importacioExportacio') {
        if (sectionControl.value) {
          this.formGroup.get('_3_1_importacio')?.setValue(true);
        } else {
          this.formGroup.get('_3_1_importacio')?.setValue(false);
          this.formGroup.get('_3_2_exportacio')?.setValue(false);
        }
      } else if (section === '_5_distribucioMinorista') {
        if (sectionControl.value) {
          this.formGroup.get('_5_1_certificatGran')?.setValue(true);
        } else {
          const controlsToReset = ['_5_1_certificatGran', '_5_2_certificatPetit', '_5_3_noCertificatEximit'];
          controlsToReset.forEach(controlName => {
            this.formGroup.get(controlName)?.setValue(false);
          });
        }
      }
      this.onBlur();
    }
  }

  onFamilyChange(family: string) {
    const familyControl = this.formGroup.get(family);
    if (familyControl) {
      if (!familyControl.value) {
        let controlsToReset: string[] = [];
        switch (family) {
          case '_1_1_produccioVegetal':
            controlsToReset = [
              '_1_1_1_llenyosos', '_1_1_2_extensius', '_1_1_3_horticoles',
              '_1_1_4_obtentorsMRV', '_1_1_5_pastures', '_1_1_6_forestals'
            ];
            break;
          case '_1_2_produccioRamadera':
            controlsToReset = [
              '_1_2_1_herbivors', '_1_2_2_porcs', '_1_2_3_avicola',
              '_1_2_4_apicola', '_1_2_5_helicicola', '_1_2_6_insectes', '_1_2_7_conills'
            ];
            break;
          case '_1_5_aquicultura':
            controlsToReset = [
              '_1_5_1_alguesIFitoplancton', '_1_5_2_molluscs', '_1_5_3_peixos'
            ];
            break;
        }
        controlsToReset.forEach(controlName => {
          this.formGroup.get(controlName)?.setValue(false);
        });
      }
      this.onBlur();
    }
  }
  
  onCertificatChange(changedControlName: string, event: any) {
    const controls = ['_5_1_certificatGran', '_5_2_certificatPetit', '_5_3_noCertificatEximit'];

    if (event) {
      controls.forEach(controlName => {
        if (controlName !== changedControlName) {
          this.formGroup.get(controlName)?.setValue(false);
        } else {
          this.formGroup.get(controlName)?.setValue(true);
        }
      });
    }
    this.onBlur();
  }

  private produccionValidator(): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const _1_produccioPrimaria = formGroup.get('_1_produccioPrimaria')?.value;
      const _2_preparacio = formGroup.get('_2_preparacio')?.value;
      const _3_importacioExportacio = formGroup.get('_3_importacioExportacio')?.value;
      const _4_distribucioMajorista = formGroup.get('_4_distribucioMajorista')?.value;
      const _5_distribucioMinorista = formGroup.get('_5_distribucioMinorista')?.value;
      const _6_altres = formGroup.get('_6_altres')?.value;

      const _1_1_produccioVegetal = formGroup.get('_1_1_produccioVegetal')?.value;
      const _1_2_produccioRamadera = formGroup.get('_1_2_produccioRamadera')?.value;
      const _1_3_recolleccioSilvestre = formGroup.get('_1_3_recolleccioSilvestre')?.value;
      const _1_4_produccioDeSal = formGroup.get('_1_4_produccioDeSal')?.value;
      const _1_5_aquicultura = formGroup.get('_1_5_aquicultura')?.value;

      const _3_1_importacio = formGroup.get('_3_1_importacio')?.value;
      const _3_2_exportacio = formGroup.get('_3_2_exportacio')?.value;

      // Rule 1: At least one of the main sections must be true
      const mainSectionValid = _1_produccioPrimaria || _2_preparacio || _3_importacioExportacio || _4_distribucioMajorista || _5_distribucioMinorista || _6_altres;

      // Rule 2: If _1_produccioPrimaria is true, at least one of its families must be true
      const produccioPrimariaValid = !_1_produccioPrimaria || (_1_1_produccioVegetal || _1_2_produccioRamadera || _1_3_recolleccioSilvestre || _1_4_produccioDeSal || _1_5_aquicultura);

      // Rule 3: If _3_importacioExportacio is true, at least one of its families must be true
      const importacioExportacioValid = !_3_importacioExportacio || (_3_1_importacio || _3_2_exportacio);

      if (mainSectionValid && produccioPrimariaValid && importacioExportacioValid) {
        return null;
      } else {
        return { produccionError: true };
      }
    };
  }

}

import {HttpService} from "../core/http.service";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../../components/toast.service";
import {Observable} from "rxjs";
import {SolicitudInscrito, SolicitudInscritoEstado} from "../../../model/operadores/solicitudInscrito";

@Injectable({
    providedIn: 'root'
})
export class SolicitudInscritoHttpService extends HttpService {

    private BASE = `solicitud-inscrito`;
    private BASE_ID = (idSolicitudInscrito: string) => `${this.BASE}/${idSolicitudInscrito}`;
    private SOLICITUD_INSCRITO_ESTADOS = `${this.BASE}/estados`;

    constructor(http: HttpClient,
                toastSvc: ToastService) {
        super(http, toastSvc);
    }

    public getSolicitudInscrito(idSolicitudInscrito: string): Observable<SolicitudInscrito> {
        return this.get(this.BASE_ID(idSolicitudInscrito));
    }

    public getSolicitudInscritoEstados(): Observable<SolicitudInscritoEstado[]> {
        return this.get(this.SOLICITUD_INSCRITO_ESTADOS)
    }

    public updateSolicitudInscrito(idSolicitudInscrito: string, solicitudInscrito: SolicitudInscrito): Observable<SolicitudInscrito> {
        return this.put(this.BASE_ID(idSolicitudInscrito), solicitudInscrito);
    }

    public enviarSolicitudInscrito(): Observable<any> {
        return this.post(this.BASE);
    }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OperadoresListadoComponent} from './pages/operadores-listado/operadores-listado.component';
import {MatCardModule} from "@angular/material/card";
import {OperadoresRoutingModule} from "./operadores-routing.module";
import {SharedModule} from "../../shared/shared.module";
import {TranslateModule} from "@ngx-translate/core";
import {OperadoresDetalleComponent} from './pages/operadores-detalle/operadores-detalle.component';
import {ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatTabsModule} from "@angular/material/tabs";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatIconModule} from "@angular/material/icon";
import {
  OperadoresDetalleGeneralComponent
} from './pages/operadores-detalle-general/operadores-detalle-general.component';
import {
  OperadoresListadoAutorizacionComponent
} from './pages/operadores-listado-autorizacion/operadores-listado-autorizacion.component';
import {
  OperadoresListadoSolicitudComponent
} from './pages/operadores-listado-solicitud/operadores-listado-solicitud.component';
import {
  OperadoresDetalleAutorizacionComponent
} from './pages/operadores-detalle-autorizacion/operadores-detalle-autorizacion.component';
import {MatDialogModule} from "@angular/material/dialog";
import {OperadoresAltaComponent} from './pages/operadores-alta/operadores-alta.component';
import {OperadoresAttachmentsComponent} from './components/operadores-attachments/operadores-attachments.component';
import {
  OperadoresListadoParcelaComponent
} from "./pages/operadores-listado-parcela/operadores-listado-parcela.component";
import {
  OperadoresDetalleParcelaComponent
} from "./pages/operadores-detalle-parcela/operadores-detalle-parcela.component";
import {
  OperadoresListadoAlmacenComponent
} from './pages/operadores-listado-almacen/operadores-listado-almacen.component';
import {
  OperadoresDetalleAlmacenComponent
} from './pages/operadores-detalle-almacen/operadores-detalle-almacen.component';
import {OperadoresListadoGranjaComponent} from './pages/operadores-listado-granja/operadores-listado-granja.component';
import {
  OperadoresDetalleSolicitudComponent
} from './pages/operadores-detalle-solicitud/operadores-detalle-solicitud.component';
import {
  OperadoresDetalleSolicitudProductoComponent
} from './pages/operadores-detalle-solicitud-producto/operadores-detalle-solicitud-producto.component';
import {OperadoresDetalleGranjaComponent} from './pages/operadores-detalle-granja/operadores-detalle-granja.component';
import {OperadoresListadoAnimalComponent} from './pages/operadores-listado-animal/operadores-listado-animal.component';
import {OperadoresDetalleAnimalComponent} from './pages/operadores-detalle-animal/operadores-detalle-animal.component';
import {
  OperadoresListadoInstalacionComponent
} from './pages/operadores-listado-instalacion/operadores-listado-instalacion.component';
import {
  OperadoresDetalleInstalacionComponent
} from './pages/operadores-detalle-instalacion/operadores-detalle-instalacion.component';

import {OperadoresAltaGeneralComponent} from './components/operadores-alta-general/operadores-alta-general.component';
import {
  OperadoresAltaProduccionComponent
} from './components/operadores-alta-produccion/operadores-alta-produccion.component';
import {
  OperadoresAltaDocumentacionComponent
} from './components/operadores-alta-documentacion/operadores-alta-documentacion.component';
import {MatListModule} from "@angular/material/list";
import {
  OperadorAddMagatzemModalComponent
} from './modals/operador-add-magatzem-modal/operador-add-magatzem-modal.component';
import {EtiquetasModule} from "../etiquetas/etiquetas.module";
import {
  EditOperadorAlmacenModalComponent
} from './modals/edit-operador-almacen-modal/edit-operador-almacen-modal.component';
import {OperadoresLocalizacionComponent} from './components/operadores-localizacion/operadores-localizacion.component';
import {
  EditOperadorInstalacionRegistroModalComponent
} from './modals/edit-operador-instalacion-registro-modal/edit-operador-instalacion-registro-modal.component';
import {
  EditPersonaContactoModalComponent
} from './modals/edit-persona-contacto-modal/edit-persona-contacto-modal.component';
import {
    DisclaimerEliminarGenericoComponent
} from './modals/disclaimer-eliminar-generico/disclaimer-eliminar-generico.component';
import {
  EditIngredienteAutorizacionModalComponent
} from './modals/edit-ingrediente-autorizacion-modal/edit-ingrediente-autorizacion-modal.component';
import {
  EditOperadorAnimalCensoModalComponent
} from "./modals/edit-operador-animal-censo-modal/edit-operador-animal-censo-modal.component";
import {MatRadioModule} from "@angular/material/radio";
import { OperadoresAltaAlmacenesComponent } from './components/operadores-alta-almacenes/operadores-alta-almacenes.component';
import { OperadoresListadoNoAutorizacionComponent } from './pages/operadores-listado-no-autorizacion/operadores-listado-no-autorizacion.component';
import { OperadoresDetalleNoAutorizacionComponent } from './pages/operadores-detalle-no-autorizacion/operadores-detalle-no-autorizacion.component';
import { OperadoresListadoAutorizacionBajaComponent } from './pages/operadores-listado-autorizacion-baja/operadores-listado-autorizacion-baja.component';
import { OperadoresDetalleAutorizacionBajaComponent } from './pages/operadores-detalle-autorizacion-baja/operadores-detalle-autorizacion-baja.component';
import { DisclaimerEliminarSolicitudComponent } from './modals/disclaimer-eliminar-solicitud/disclaimer-eliminar-solicitud.component';
import { OperadoresExternalEntryPointComponent } from './pages/operadores-external-entry-point/operadores-external-entry-point.component';
import { OperadoresSolicitudInscritoComponent } from './pages/operadores-solicitud-inscrito/operadores-solicitud-inscrito.component';


@NgModule({
  declarations: [
    OperadoresListadoComponent,
    OperadoresDetalleComponent,
    OperadoresDetalleGeneralComponent,
    OperadoresListadoParcelaComponent,
    OperadoresDetalleParcelaComponent,
    OperadoresListadoAutorizacionComponent,
    OperadoresListadoSolicitudComponent,
    OperadoresDetalleAutorizacionComponent,
    OperadoresAltaComponent,
    OperadoresAttachmentsComponent,
    OperadoresListadoAlmacenComponent,
    OperadoresDetalleAlmacenComponent,
    OperadoresListadoGranjaComponent,
    OperadoresDetalleSolicitudComponent,
    OperadoresDetalleSolicitudProductoComponent,
    OperadoresDetalleGranjaComponent,
    OperadoresListadoAnimalComponent,
    OperadoresDetalleAnimalComponent,
    OperadoresListadoInstalacionComponent,
    OperadoresDetalleInstalacionComponent,
    OperadoresDetalleGranjaComponent,
    OperadoresAltaGeneralComponent,
    OperadoresAltaProduccionComponent,
    OperadoresAltaDocumentacionComponent,
    OperadorAddMagatzemModalComponent,
    EditOperadorAlmacenModalComponent,
    OperadoresLocalizacionComponent,
    EditOperadorInstalacionRegistroModalComponent,
    EditPersonaContactoModalComponent,
    EditOperadorInstalacionRegistroModalComponent,
    EditOperadorAnimalCensoModalComponent,
    DisclaimerEliminarGenericoComponent,
    EditIngredienteAutorizacionModalComponent,
    OperadoresAltaAlmacenesComponent,
    OperadoresListadoNoAutorizacionComponent,
    OperadoresDetalleNoAutorizacionComponent,
    OperadoresListadoAutorizacionBajaComponent,
    OperadoresDetalleAutorizacionBajaComponent,
    DisclaimerEliminarSolicitudComponent,
    OperadoresExternalEntryPointComponent,
    OperadoresSolicitudInscritoComponent
  ],
    imports: [
        CommonModule,
        MatCardModule,
        OperadoresRoutingModule,
        SharedModule,
        TranslateModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatTabsModule,
        MatExpansionModule,
        MatIconModule,
        MatDialogModule,
        MatListModule,
        EtiquetasModule,
        MatRadioModule
    ],
    exports: [
        OperadoresListadoComponent,
        OperadoresDetalleComponent
    ]
})
export class OperadoresModule { }

import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {OperadoresListadoComponent} from "./pages/operadores-listado/operadores-listado.component";
import {OperadoresDetalleComponent} from "./pages/operadores-detalle/operadores-detalle.component";
import {
  OperadoresDetalleAlmacenComponent
} from "./pages/operadores-detalle-almacen/operadores-detalle-almacen.component";
import {
  OperadoresDetalleInstalacionComponent
} from "./pages/operadores-detalle-instalacion/operadores-detalle-instalacion.component";
import {
  OperadoresDetalleParcelaComponent
} from "./pages/operadores-detalle-parcela/operadores-detalle-parcela.component";
import {
  OperadoresDetalleSolicitudComponent
} from "./pages/operadores-detalle-solicitud/operadores-detalle-solicitud.component";
import {
  OperadoresDetalleSolicitudProductoComponent
} from "./pages/operadores-detalle-solicitud-producto/operadores-detalle-solicitud-producto.component";
import {OperadoresDetalleGranjaComponent} from "./pages/operadores-detalle-granja/operadores-detalle-granja.component";
import {OperadoresDetalleAnimalComponent} from "./pages/operadores-detalle-animal/operadores-detalle-animal.component";
import {OperadoresAltaComponent} from "./pages/operadores-alta/operadores-alta.component";
import {
  OperadoresDetalleAutorizacionComponent
} from "./pages/operadores-detalle-autorizacion/operadores-detalle-autorizacion.component";
import {
  OperadoresDetalleNoAutorizacionComponent
} from "./pages/operadores-detalle-no-autorizacion/operadores-detalle-no-autorizacion.component";
import {
  OperadoresExternalEntryPointComponent
} from "./pages/operadores-external-entry-point/operadores-external-entry-point.component";
import {ExternalOperadoresGuard} from "../../core/guards/external-operadores.guard";
import {
  OperadoresSolicitudInscritoComponent
} from "./pages/operadores-solicitud-inscrito/operadores-solicitud-inscrito.component";


export const OperadoresRouting: Routes = [
  {
    path: '',
    component: OperadoresListadoComponent,
  },
  {
    path: 'external',
    component: OperadoresExternalEntryPointComponent,
    canActivate: [ExternalOperadoresGuard]
  },
  {
    path: 'alta-operador',
    component: OperadoresAltaComponent
  },
  {
    path: 'solicitud-inscrito',
    component: OperadoresSolicitudInscritoComponent
  },
  {
    path: ':action',
    component: OperadoresDetalleComponent
  },
  {
    path: ':idOperador/:action',
    component: OperadoresDetalleComponent
  },
  {
    path: ':idOperador/:action/magatzem/:numOrdenAlmacen/:almacenAction',
    component: OperadoresDetalleAlmacenComponent
  },
  {
    path: ':idOperador/:action/magatzem/:almacenAction',
    component: OperadoresDetalleAlmacenComponent
  },
  {
    path: ':idOperador/:action/parcela/:idParcela/:parcelaAction',
    component: OperadoresDetalleParcelaComponent
  },
  {
    path: ':idOperador/:action/parcela/:parcelaAction',
    component: OperadoresDetalleParcelaComponent
  },
  {
    path: ':idOperador/:action/granja/:numGranja/:granjaAction',
    component: OperadoresDetalleGranjaComponent
  },
  {
    path: ':idOperador/:action/granja/:granjaAction',
    component: OperadoresDetalleGranjaComponent
  },
  {
    path: ':idOperador/:action/instalacio/:numOrdenInstalacion/:instalacionAction',
    component: OperadoresDetalleInstalacionComponent
  },
  {
    path: ':idOperador/:action/instalacio/:instalacionAction',
    component: OperadoresDetalleInstalacionComponent
  },
  {
    path: ':idOperador/:action/animal/:numGranja/:idEspecie/:idRaza/:idTipoGanado/:animalAction',
    component: OperadoresDetalleAnimalComponent
  },
  {
    path: ':idOperador/:action/animal/:animalAction',
    component: OperadoresDetalleAnimalComponent
  },
  {
    path: ':idOperador/:action/solicitud/:idSolicitud',
    component: OperadoresDetalleSolicitudComponent
  },
  {
    path: ':idOperador/:action/solicitud/:idSolicitud/producte/:idSolicitudProducto',
    component: OperadoresDetalleSolicitudProductoComponent
  },
  {
    path: ':idOperador/:action/autoritzacio/:idAutorizacion/:autorizacionAction',
    component: OperadoresDetalleAutorizacionComponent
  },
  {
    path: ':idOperador/:action/autoritzacio/:autorizacionAction',
    component: OperadoresDetalleAutorizacionComponent
  },
  {
    path: ':idOperador/:action/noAutoritzacio/:idSolicitudProducto/:noAutorizacionAction',
    component: OperadoresDetalleNoAutorizacionComponent
  },
  {
    path: ':idOperador/:action/noAutoritzacio/:noAutorizacionAction',
    component: OperadoresDetalleNoAutorizacionComponent
  },
  {
    path: "**",
    redirectTo: "/"
  }
];

@NgModule({
  imports: [RouterModule.forChild(OperadoresRouting)],
  exports: [RouterModule],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class OperadoresRoutingModule { }

import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, firstValueFrom, Observable } from 'rxjs';
import { ToastService } from '../../../core/services/components/toast.service';
import { TranslateService } from '@ngx-translate/core';
import {
    AuthService,
    JWTDataExternalSolicitudInscrito
} from '../../../core/services/security/auth.service';
import { ECityClicHttpService } from '../../../core/services/http/solicitud/e-city-clic.http.service';
import {SolicitudInscrito} from "../../../core/model/operadores/solicitudInscrito";
import {SolicitudInscritoHttpService} from "../../../core/services/http/operadores/solicitud-inscrito.http.service";


@Injectable({
    providedIn: 'root'
})
export class OperadoresService {

    // Basic values
    protected _idSolicitudInscrito: string;
    protected _isInitialized = false;
    private _solicitudInscrito: SolicitudInscrito;

    // Services
    private activatedRoute: ActivatedRoute;
    private router: Router;
    protected toastSvc: ToastService;
    protected translateSvc: TranslateService;
    protected solicitudInscritoSvc: SolicitudInscritoHttpService;
    protected semicSvc: ECityClicHttpService;
    protected authSvc: AuthService;

    constructor(protected injector: Injector) {
        this.activatedRoute = injector.get(ActivatedRoute);
        this.router = injector.get(Router);
        this.toastSvc = injector.get(ToastService);
        this.translateSvc = injector.get(TranslateService);
        this.solicitudInscritoSvc = injector.get(SolicitudInscritoHttpService);
        this.semicSvc = injector.get(ECityClicHttpService);
        this.authSvc = injector.get(AuthService);
    }

    public initTramite(jwtData: JWTDataExternalSolicitudInscrito) {
        this._isInitialized = true;
        this._idSolicitudInscrito = jwtData.idSolicitudInscrito;

        if (this.hasRequiredParamaters) {
            this.cargarDatosVisualizacion();
        } else {
            this.handleErrorLoadingData();
        }
    }

    public async cargarDatosVisualizacion() {
        try {
            this._solicitudInscrito = await firstValueFrom(this.solicitudInscritoSvc.getSolicitudInscrito(this._idSolicitudInscrito));
            this.redirectToTramitePage(this._solicitudInscrito);
        } catch (e) {
            this.handleErrorLoadingData();
        }
    }

    public enviarSolicitudInscrito() {
        return this.semicSvc.enviarSolicitudInscrito(this._idSolicitudInscrito);
    }

    public enviarConfirmacion() {
        return this.semicSvc.confirmarSolicitud(this._idSolicitudInscrito);
    }

    public enviarFinalizacion() {
        return this.semicSvc.finalizarSolicitud(this._idSolicitudInscrito);
    }

    public get hasRequiredParamaters(): boolean {
        return this._idSolicitudInscrito != null;
    }

    // Getters
    get solicitudInscrito(): SolicitudInscrito {
        return this._solicitudInscrito;
    }

    public get idSolicitudInscrito(): string {
        return this._idSolicitudInscrito;
    }

    public get initialized(): boolean {
        return this._isInitialized;
    }

    public handleErrorLoadingData(): Observable<any> {
        this.toastSvc.show(this.translateSvc.instant('OPERADORES.faltaInformacionTramite'));
        this.authSvc.logout();
        return EMPTY;
    }

    private redirectToTramitePage(solicitudInscrito: SolicitudInscrito) {
        this.router.navigate(['operadors', 'solicitud-inscrito']);
    }
}

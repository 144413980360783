import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { ExternalGuard } from './external.guard';
import {AuthService, JWTDataExternalSolicitudInscrito} from '../services/security/auth.service';
import {OperadoresService} from "../../modules/operadores/services/operadores.service";


@Injectable({
    providedIn: 'root'
})
export abstract class ExternalOperadoresGuard extends ExternalGuard implements CanActivate {

    constructor(authSvc: AuthService,
                public operadoresSvc: OperadoresService) {
        super(authSvc);
    }

    override preIsAllowed(route: ActivatedRouteSnapshot) {
        super.preIsAllowed(route);

        let token = this.getToken();

        if (token != null) {
            const dataToken = AuthService.extractData(token) as JWTDataExternalSolicitudInscrito;
            this.operadoresSvc.initTramite(dataToken);
        }
    }
}

import {Injectable} from '@angular/core';
import {HttpService} from "../core/http.service";
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../../components/toast.service";
import {Observable, shareReplay, tap} from "rxjs";
import {SolicitudProducto} from "../../../model/etiquetas/solicitud-producto";
import {Solicitud} from "../../../model/solicitud/solicitud";
import {Etiqueta} from "../../../model/etiquetas/etiqueta";
import {AutorizacionPk} from "../../../model/autorizacion/autorizacion";
import {Pageable} from "../../../model/core/pageable";
import {CodigoTramite} from "../../../model/solicitud/codigoTramite";
import {TipoTramite} from "../../../model/solicitud/tipoTramite";
import {EstadoSolicitud} from "../../../model/solicitud/estadoSolicitud";


@Injectable({
  providedIn: 'root'
})
export class SolicitudHttpService extends HttpService {

  private BASE = `solicitudes`;
  private BASE_ID = (idSolicitud: string) => `${this.BASE}/${idSolicitud}`;
  private PRODUCTOS = (idSolicitud: string) => `${this.BASE}/${idSolicitud}/productos`;
  private PRODUCTOS_FINALIZACION = (idSolicitud: string) => `${this.BASE}/${idSolicitud}/productos-finalizacion`;
  private PRODUCTOS_PAGEABLE = (idSolicitud: string) => `${this.BASE}/${idSolicitud}/productosPageable`;
  private ETIQUETA = (idSolicitud: string) => `${this.BASE}/${idSolicitud}/etiqueta`;
  protected ETIQUETA_DOWN = (idSolicitud: string) => `${this.BASE}/${idSolicitud}/etiqueta/download`;
  protected DUPLICAR = `${this.BASE}/duplicar`;
  protected ACTUALIZAR = `${this.BASE}/actualizar`;
  private ELIMINAR = (idSolicitud: string) => `${this.BASE}/${idSolicitud}/eliminar`;
  private CODIGOS_TRAMITES = `${this.BASE}/codigos-tramites`;
  private TIPOS_TRAMITES = `${this.BASE}/tipos-tramites`;
  private ESTADOS_SOLICITUD = `${this.BASE}/estados-solicitud`;

  constructor(http: HttpClient,
              toastSvc: ToastService) {
    super(http, toastSvc);
  }

  public getSolicitud(idSolicitud: string): Observable<Solicitud> {
    return this.get(this.BASE_ID(idSolicitud));
  }
  public getProductos(idSolicitud: string): Observable<SolicitudProducto[]>{
    return this.get(this.PRODUCTOS(idSolicitud));
  }

  public getAllProductos(idSolicitud: string): Observable<SolicitudProducto[]>{
    return this.get(this.PRODUCTOS_FINALIZACION(idSolicitud));
  }

  public getProductosPageable(idSolicitud: string, page: number | null = null, filtros: object = {}): Observable<Pageable<SolicitudProducto>>{
    let httpParams = this.objectToHttpParams(filtros);
    if (page) {
      httpParams = httpParams.set("page", page - 1);
    }
    return this.get(this.PRODUCTOS_PAGEABLE(idSolicitud), httpParams)
        .pipe(tap(page => page.content = page.content.map(item => Object.assign(new SolicitudProducto(), item))));
  }

  public getEtiqueta(idSolicitud: string): Observable<Etiqueta>{
    return this.get(this.ETIQUETA(idSolicitud));
  }

  public crearEtiqueta(idSolicitud : string, file: File): Observable<Etiqueta> {
    let formData = new FormData;
    formData.append("file", file);
    return this.post(this.ETIQUETA(idSolicitud), formData);
  }

  public eliminarEtiqueta(idSolicitud: string) : Observable<any> {
    return this.delete(this.ETIQUETA(idSolicitud));
  }

  public descargarEtiqueta(idSolicitud: string) : void {
    return this.downloadFile(this.ETIQUETA_DOWN(idSolicitud));
  }

  public visualizarEtiqueta(idSolicitud) {
    this.downloadFile(this.ETIQUETA_DOWN(idSolicitud));
  }
  public duplicarProductos(idSolicitud: string, productosSeleccionados: AutorizacionPk[]): Observable<SolicitudProducto[]> {
    return this.post(this.DUPLICAR, {
      idSolicitud, "idsSolicitudProductos": productosSeleccionados
    });
  }

  public actualizarProductos(idSolicitud: string, productosSeleccionados: AutorizacionPk[]): Observable<SolicitudProducto[]> {
    return this.post(this.ACTUALIZAR, {
      idSolicitud, "idsSolicitudProductos": productosSeleccionados
    });
  }

  public eliminarSolicitud(idSolicitud: string, motivoDelete: string, usuarioLogeado: string): Observable<any> {
    return this.delete(`${this.ELIMINAR(idSolicitud)}/${motivoDelete}/${usuarioLogeado}`);
  }

  public getCodigosTramites(): Observable<CodigoTramite[]> {
    return this.get(this.CODIGOS_TRAMITES)
        .pipe(shareReplay());
  }

  public getTiposTramites(): Observable<TipoTramite[]> {
    return this.get(this.TIPOS_TRAMITES)
        .pipe(shareReplay());
  }

  public getEstadosSolicitud(): Observable<EstadoSolicitud[]> {
    return this.get(this.ESTADOS_SOLICITUD)
        .pipe(shareReplay());
  }

}
